<template>
  <v-theme-provider light>
    <base-section id="payment" class="text-center" space="86">
      <base-section-heading title="Application submitted" color="accent"> Stay tuned while we are reviewing your application </base-section-heading>
      <v-responsive class="d-flex align-center mx-auto mb-4" height="100%" max-width="700" width="100%">
        <v-container>
          <v-row>
            <v-col class="col">
              <v-alert border="left" type="success" color="primary" dark>
                <div>Your application has been submitted.</div>
              </v-alert>
            </v-col>
          </v-row>
          <v-footer :fixed="true">
            <v-row justify="center" align="center" class="footer">
              <v-col cols="12" sm="12">
                <v-container>While you wait for approval, you can refine your <a href="javascript:;" @click="editProfileAction()">application</a></v-container>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-responsive>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: 'StayTuned',
  methods: {
    editProfileAction() {
      this.$router.push({
        name: 'editprofileroute'
      })
    }
  }
}
</script>
